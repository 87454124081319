import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Breadcrumbs,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import type { AppBarProps } from "@mui/material";
import { Menu as MenuIcon } from "../../icons/menu";
import { sidebarWidth } from "shared/theme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClientHeaderCard from "../advisor/client-header-card";
import { useTheme } from "shared/hooks/use-theme";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";

type DashboardNavbarProps = {
  onOpenSidebar?: () => void;
  useClientHeader?: boolean;
  hideBorder?: boolean;
  title?: string;
  breadcrumbItems?: JSX.Element[];
  rightContent?: ReactNode;
} & AppBarProps;

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const {
    onOpenSidebar,
    useClientHeader,
    title,
    breadcrumbItems,
    rightContent,
    hideBorder,
    ...other
  } = props;
  const theme = useTheme();

  const isAdvisorLoggedInAsClient = !!loggedInAsAdvisorID();

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: theme.palette.background.default,
          borderBottomColor: theme.palette.divider,
          borderBottomStyle: "solid",
          borderBottomWidth: hideBorder ? 0 : 1,
          boxShadow: "none",
          left: {
            lg: sidebarWidth,
          },
          top: isAdvisorLoggedInAsClient ? "64px" : "",
          width: {
            lg: `calc(100% - ${sidebarWidth})`,
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
          }}
        >
          <IconButton
            onClick={onOpenSidebar}
            sx={{
              display: {
                xs: "inline-flex",
                lg: "none",
              },
              ml: 1,
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ ml: 5 }}>
            {useClientHeader && (
              <div>
                <ClientHeaderCard breadcrumbItems={breadcrumbItems} />
              </div>
            )}
            {!useClientHeader && breadcrumbItems && breadcrumbItems.length > 1 && (
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbItems}
              </Breadcrumbs>
            )}
            {!useClientHeader && !breadcrumbItems && title && (
              <Typography variant="subtitle1" color="text.charcoal">
                {title}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {rightContent && <Box sx={{ mr: 4 }}>{rightContent}</Box>}
        </Toolbar>
      </AppBar>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
