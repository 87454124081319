import {
  Business,
  Home,
  AirportShuttle,
  Category,
  Policy,
  Style,
} from "@mui/icons-material";
import { renderItemIcon } from "src/components/portfolio/views/render-item-icon";
import { AssetClassName } from "shared/generated/string_unions/graphql";
import { ChartPie } from "src/icons/chart-pie";
import { financialAccountIconColors } from "shared/theme";
import defaultGuard from "shared/utils/default-guard";
import { formatItemValue } from "./portfolio-item";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";

export const RenderIconForAssetFragment = graphql(/* GraphQL */ `
  fragment RenderIconForAsset on Asset {
    assetClass
    assetSubclass
  }
`);

export function renderIconForAsset(
  assetFrag: FragmentOf<typeof RenderIconForAssetFragment>
) {
  const asset = unmaskFragment(RenderIconForAssetFragment, assetFrag);
  switch (asset.assetClass) {
    case "real_estate":
      if (asset.assetSubclass === "non_residence") {
        return renderItemIcon(
          financialAccountIconColors.realEstateAsset,
          <Business fontSize="small" htmlColor="#fff" />
        );
      }
      return renderItemIcon(
        financialAccountIconColors.realEstateAsset,
        <Home fontSize="small" htmlColor="#fff" />
      );
    case "company_equity":
      return renderItemIcon(
        financialAccountIconColors.companyEquity,
        <ChartPie fontSize="small" htmlColor="#fff" />
      );
    case "vehicle":
      return renderItemIcon(
        financialAccountIconColors.otherAssets,
        <AirportShuttle fontSize="small" htmlColor="#fff" />
      );
    case "collectible":
      return renderItemIcon(
        financialAccountIconColors.otherAssets,
        <Category fontSize="small" htmlColor="#fff" />
      );
    case "insurance_policy":
      return renderItemIcon(
        financialAccountIconColors.otherAssets,
        <Policy fontSize="small" htmlColor="#fff" />
      );
    case "other":
      return renderItemIcon(
        financialAccountIconColors.otherAssets,
        <Style fontSize="small" htmlColor="#fff" />
      );
    default:
      defaultGuard(asset.assetClass);
      return renderItemIcon(
        financialAccountIconColors.otherAssets,
        <Style fontSize="small" htmlColor="#fff" />
      );
  }
}

export function assetDeletionText(assetClass: AssetClassName) {
  let assetName1 = "an asset";
  let assetName2 = "asset";
  switch (assetClass) {
    case "real_estate":
      assetName1 = "a property";
      assetName2 = "property";
      break;
    case "company_equity":
      assetName1 = "company equity";
      assetName2 = "company equity";
      break;
    case "collectible":
    case "insurance_policy":
    case "vehicle":
    case "other":
      break;
    default:
      defaultGuard(assetClass);
      break;
  }
  return `When you delete ${assetName1} it will no longer appear in your list of accounts, assets, and liabilities. Historical values of this ${assetName2} will be removed from your net worth graph.`;
}

export function assetDeleteButtonText(assetClass: AssetClassName) {
  if (assetClass === "company_equity") {
    return "Delete company equity";
  } else if (assetClass === "real_estate") {
    return "Delete real estate";
  }
  return "Delete asset";
}

export const AssetTitleFragment = graphql(/* GraphQL */ `
  fragment AssetTitle on Asset {
    assetClass
    companyEquityCompanyName
    description
  }
`);

export function assetTitle(assetFrag: FragmentOf<typeof AssetTitleFragment>) {
  const asset = unmaskFragment(AssetTitleFragment, assetFrag);
  if (asset.assetClass === "company_equity") {
    return asset.companyEquityCompanyName;
  }
  return asset.description;
}

export function assetValueTitle(asset: { value: { amount: string } }) {
  return formatItemValue(asset.value.amount);
}

export function assetValueSubtitle(asset: {
  liabilities: { currentBalance: { amount: string } }[];
}) {
  if (asset.liabilities.length > 0) {
    return `${formatItemValue(
      asset.liabilities[0].currentBalance.amount
    )} mortgage balance`;
  }
  return null;
}
