import { renderProviderImg } from "src/utils/image";
import numeral from "numeral";
import { Avatar, Box } from "@mui/material";
import {
  PortfolioItem,
  PortfolioItemGroupName,
} from "shared/generated/graphql";
import { ReactNode } from "react";

import { accountDeleteButtonText, accountValueTitle } from "./account";
import defaultGuard from "shared/utils/default-guard";
import {
  assetDeleteButtonText,
  assetDeletionText,
  assetValueTitle,
} from "./asset";
import {
  LIABILITY_DELETE_BUTTON_TEXT,
  LIABILITY_DELETION_TEXT,
  liabilityValueTitle,
} from "./liability";
import { SleeveTagIcon } from "src/components/net-worth/icons/sleeve-tag";
import { SAVVY_WOB_URL } from "src/constants/logo-urls/savvy-wob-logo";

export function renderItemIcon(background: string, component: ReactNode) {
  return (
    <Avatar variant="circular" sx={{ background }}>
      {component}
    </Avatar>
  );
}

export function renderSleeveIcon() {
  return (
    <>
      {renderProviderImg(SAVVY_WOB_URL, 40)}
      <Box zIndex={1} display="flex" ml={-1.75} mb={-0.5}>
        <SleeveTagIcon />
      </Box>
    </>
  );
}

export function formatItemValue(value: number | string) {
  return numeral(value).format("$0,0.00");
}

/**
 * @deprecated please use accountDeletionText, LIABILITY_DELETION_TEXT, or assetDeletionText
 */
export function itemDeleteBodyText(item: PortfolioItem) {
  switch (item.__typename) {
    case "Account":
      return null; // This case will never be hit anymore
    case "Liability":
      return LIABILITY_DELETION_TEXT;
    case "Asset":
      return assetDeletionText(item.assetClass);
    case undefined:
      return "When you delete an item it will no longer appear in your list of accounts, assets, and liabilities. Historical values of this item will be removed from your net worth graph.";
    default:
      defaultGuard(item);
      return "When you delete an item it will no longer appear in your list of accounts, assets, and liabilities. Historical values of this item will be removed from your net worth graph.";
  }
}

/**
 * @deprecated please use accountDeleteButtonText, LIABILITY_DELETE_BUTTON_TEXT, or assetDeleteButtonText
 */
export function itemDeleteButtonText(item: PortfolioItem) {
  switch (item.__typename) {
    case "Account":
      // @ts-expect-error This is a deprecated function
      return accountDeleteButtonText(item);
    case "Liability":
      return LIABILITY_DELETE_BUTTON_TEXT;
    case "Asset":
      return assetDeleteButtonText(item.assetClass);
    case undefined:
      return "Delete item";
    default:
      defaultGuard(item);
      return "Delete item";
  }
}

/**
 * @deprecated please use accountValueTitle, assetValueTitle, or liabilityValueTitle
 */
export function portfolioItemValueTitle(
  item: PortfolioItem,
  portfolioItemGroupName: PortfolioItemGroupName
) {
  switch (item.__typename) {
    case "Asset":
      return assetValueTitle(item);
    case "Account":
      return accountValueTitle({
        account: item,
        portfolioItemGroupName,
      });
    case "Liability":
      return liabilityValueTitle(item);
    case undefined:
      return "-";
    default:
      defaultGuard(item);
      return "-";
  }
}

export function portfolioItemSecondaryValueSubtitle(item: {
  hiddenFromNetWorth?: boolean | null;
}) {
  if (item.hiddenFromNetWorth) {
    return "Not in net worth";
  }
  return null;
}
