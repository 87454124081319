import { Avatar, Breadcrumbs, CardHeader } from "@mui/material";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import { XCircularLoadingIndicator } from "shared/x-components/x-circular-loading-indicator";
import { useRouter } from "next/router";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatInactiveBreadcrumb } from "src/utils/breadcrumbs";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { graphql } from "shared/gql";
import assert from "assert";

const CLIENT_QUERY = graphql(/* GraphQL */ `
  query ClientFromClientHeaderCard($id: ID!) {
    client(id: $id) {
      user {
        id
        firstName
        lastName
        email
        profilePicUrl
      }
    }
  }
`);

type ClientHeaderCardProps = {
  clientId?: string;
  breadcrumbItems?: JSX.Element[];
};

function ClientHeaderCard({
  clientId,
  breadcrumbItems,
}: ClientHeaderCardProps) {
  const router = useRouter();
  const { clientUserId } = router.query;
  assert(
    typeof clientUserId === "string" || clientUserId === undefined,
    `clientUserId should be a string or undefined, but it is ${typeof clientUserId}`
  );
  const { loading, error, data } = useQueryWithErrorLogging(CLIENT_QUERY, {
    variables: {
      id: clientId ?? clientUserId ?? "",
    },
  });

  const client = data?.client;
  const clientName = `${client?.user.firstName} ${client?.user.lastName}`;

  const clientNameBreadcrumb = formatInactiveBreadcrumb({
    title: clientName,
  });
  const breadcrumbsWithClientName = [clientNameBreadcrumb].concat(
    breadcrumbItems || []
  );
  return (
    <>
      {loading && <XCircularLoadingIndicator />}
      {error && `Error! ${error.message}`}
      {data && (
        <CardHeader
          avatar={
            <Avatar src={client?.user.profilePicUrl ?? ""}>
              <UserCircleIcon fontSize="small" />
            </Avatar>
          }
          title={
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbsWithClientName}
            </Breadcrumbs>
          }
          subheader={client?.user.email}
          sx={{ height: "64px" }}
        />
      )}
    </>
  );
}

export default ClientHeaderCard;
