import { AuthContextValue } from "shared/contexts/jwt-context";
import {
  Account,
  PortfolioItemGroupName,
  ProviderAccountSource,
} from "shared/generated/string_unions/graphql";
import { FragmentOf, ID, graphql, unmaskFragment } from "shared/gql";
import { renderProviderImg } from "src/utils/image";
import { financialAccountIconColors } from "shared/theme";
import { AccountBalance, TrendingUp } from "@mui/icons-material";
import { renderItemIcon } from "src/components/portfolio/views/render-item-icon";
import { DEPRECATED_ACCOUNT_SUBTYPE_DISPLAY_NAMES } from "src/constants/account-link";
import { formatItemValue } from "./portfolio-item";
import { SAVVY_WOB_URL } from "src/constants/logo-urls/savvy-wob-logo";

/**
 * @deprecated Please use IsDisconnectedLinkedAccount instead
 */
export function deprecated_isDisconnectedLinkedAccount(account: Account) {
  return ["ITEM_LOGIN_REQUIRED", "FAILED", "NO_ACCOUNTS"].includes(
    account.providerAccount?.sourceStatus || ""
  );
}
export const IsDisconnectedLinkedAccountFragment = graphql(/* GraphQL */ `
  fragment IsDisconnectedLinkedAccount on Account {
    providerAccount {
      sourceStatus
    }
  }
`);
export function isDisconnectedLinkedAccount({
  accountFrag,
}: {
  accountFrag: FragmentOf<typeof IsDisconnectedLinkedAccountFragment>;
}) {
  const account = unmaskFragment(
    IsDisconnectedLinkedAccountFragment,
    accountFrag
  );
  return ["ITEM_LOGIN_REQUIRED", "FAILED", "NO_ACCOUNTS"].includes(
    account.providerAccount?.sourceStatus || ""
  );
}

export function isPlaidLinkedAccount({
  providerAccountSource,
}: {
  providerAccountSource: ProviderAccountSource | null | undefined;
}) {
  return providerAccountSource === "PLAID";
}

export const getManagedAccountURI = (
  accountId: ID,
  advisorView?: boolean,
  salesforceAccountId?: null | ID
) => {
  if (advisorView && salesforceAccountId) {
    // New Advisor Dashboard
    return `/advisor/dashboard/client/${salesforceAccountId}/portfolio/account/${accountId}`;
  }
  // Client portfolio view
  return `/portfolio/accounts/${accountId}`;
};

export const getHeldawayAccountURI = (
  account: {
    id: ID;
  },
  advisorView: boolean | null | undefined,
  salesforceAccountId: ID | null | undefined
) => {
  if (advisorView && salesforceAccountId) {
    return getHeldawayAccountURIForAdvisors(account, salesforceAccountId);
  }
  return getHeldawayAccountURIForClients(account);
};

const getHeldawayAccountURIForAdvisors = (
  account: { id: ID },
  salesforceAccountId?: null | ID
) => {
  return `/advisor/dashboard/client/${salesforceAccountId}/portfolio/external-account/${account.id}`;
};
const getHeldawayAccountURIForClients = (account: { id: ID }) => {
  return `/portfolio/external-accounts/${account.id}`;
};

export const IsLinkedAccountFragment = graphql(/* GraphQL */ `
  fragment IsLinkedAccount on Account {
    providerAccount {
      __typename
    }
    providerAccountId
  }
`);
export const isLinkedAccount = (
  accountFrag: FragmentOf<typeof IsLinkedAccountFragment>
) => {
  const account = unmaskFragment(IsLinkedAccountFragment, accountFrag);
  return account.providerAccount || account.providerAccountId;
};

export const isAdvisor = (auth: AuthContextValue) => {
  return auth.user?.profileType === "ADVISOR";
};

export function padBulletsToMaskedAccountNumber(mask: string) {
  return mask.padStart(6, "•");
}

export const RenderIconForAccountFragment = graphql(/* GraphQL */ `
  fragment RenderIconForAccount on Account {
    custody
    offeredBySavvy
    providerLogoUrl
    type
  }
`);

export function renderIconForAccount({
  accountFrag,
  size = 40,
}: {
  accountFrag: FragmentOf<typeof RenderIconForAccountFragment>;
  size?: number;
}) {
  const account = unmaskFragment(RenderIconForAccountFragment, accountFrag);
  if (account.custody === "managed" || account.offeredBySavvy) {
    return renderProviderImg(SAVVY_WOB_URL, size);
  }
  if (account.providerLogoUrl) {
    return renderProviderImg(account.providerLogoUrl, size);
  }
  if (account.type === "investment") {
    return renderItemIcon(
      financialAccountIconColors.investmentAccount,
      <TrendingUp fontSize="small" htmlColor="#fff" />
    );
  }

  return renderItemIcon(
    financialAccountIconColors.bankAccount,
    <AccountBalance fontSize="small" htmlColor="#fff" />
  );
}

export const AccountDeleteButtonTextFragment = graphql(
  /* GraphQL */ `
    fragment AccountDeleteButtonText on Account {
      ...IsLinkedAccount
      providerAccount {
        source
      }
      providerAccountId
      subtype
      type
    }
  `,
  [IsLinkedAccountFragment]
);
export function accountDeleteButtonText(
  accountFrag: FragmentOf<typeof AccountDeleteButtonTextFragment>
) {
  const account = unmaskFragment(AccountDeleteButtonTextFragment, accountFrag);
  if (isLinkedAccount(account)) {
    return "Remove account";
  }

  if (account.type === "investment") {
    return "Delete investment account";
  } else if (account.type === "depository") {
    return "Delete bank account";
  }
  return "Delete account";
}

export const AccountTitleFragment = graphql(/* GraphQL */ `
  fragment AccountTitle on Account {
    accountName
    custody
    officialName
    providerAccount {
      providerName
    }
    subtype
    type
  }
`);

export function accountTitle({
  accountFrag,
}: {
  accountFrag: FragmentOf<typeof AccountTitleFragment>;
}) {
  const account = unmaskFragment(AccountTitleFragment, accountFrag);
  if (
    account.providerAccount &&
    account.custody !== "managed" &&
    account.type === "investment"
  ) {
    if (account.subtype && account.providerAccount.providerName) {
      return account.officialName || account.accountName;
    }
    return account.providerAccount.providerName;
  }
  return account.officialName || account.accountName;
}

export const AccountSubtitleFragment = graphql(/* GraphQL */ `
  fragment AccountSubtitle on Account {
    accountName
    custody
    loanProvider
    offeredBySavvy
    officialName
    providerAccount {
      providerName
    }
    subtype
    type
  }
`);
export function accountSubtitle({
  accountFrag,
}: {
  accountFrag: FragmentOf<typeof AccountSubtitleFragment>;
}) {
  const account = unmaskFragment(AccountSubtitleFragment, accountFrag);
  if (account.offeredBySavvy) {
    const provider =
      account.type === "loan" && account.loanProvider
        ? account.loanProvider
        : account.providerAccount?.providerName || "Charles Schwab";
    return `${provider} • Offered through Savvy`;
  }
  if (account.custody === "managed" || account.custody === "unmanaged") {
    return `${
      DEPRECATED_ACCOUNT_SUBTYPE_DISPLAY_NAMES[account.subtype || "other"]
    } • ${account.providerAccount?.providerName || "Charles Schwab"}`;
  }

  if (account.providerAccount?.providerName) {
    return account.providerAccount.providerName;
  }

  if (account.subtype && account.subtype !== "other") {
    if (account.providerAccount?.providerName) {
      return account.officialName || account.accountName;
    }
    return DEPRECATED_ACCOUNT_SUBTYPE_DISPLAY_NAMES.other;
  }
  if (!account.providerAccount?.providerName) {
    return null;
  }
  return `${account.providerAccount.providerName} ${
    DEPRECATED_ACCOUNT_SUBTYPE_DISPLAY_NAMES[account.subtype || "other"]
  }`;
}

export function accountValueTitle({
  account,
  portfolioItemGroupName,
}: {
  account: {
    displayBalance: {
      amount: string;
    };
  };
  portfolioItemGroupName: PortfolioItemGroupName;
}) {
  if (portfolioItemGroupName === "OTHER_LIABILITIES") {
    return formatItemValue(-account.displayBalance.amount);
  }
  return formatItemValue(account.displayBalance.amount);
}
