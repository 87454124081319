import { useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import DashboardSidebar from "./dashboard-sidebar";
import { Box } from "@mui/material";
import { sidebarWidth } from "shared/theme";
import { useRouter } from "next/router";
import { AdvisorImpersonationBar } from "./dashboard-advisor-impersonation-bar";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";

type DashboardLayoutProps = {
  children?: ReactNode;
  headerTitle?: string;
  useClientHeader?: boolean;
  hideBorder?: boolean;
  headerBreadcrumbs?: JSX.Element[];
  headerRightContent?: ReactNode;
};
let isAdvisorLoggedInAsClient = false;
if (typeof window !== "undefined") {
  isAdvisorLoggedInAsClient = !!loggedInAsAdvisorID();
}
const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: !isAdvisorLoggedInAsClient ? 64 : 128,
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: sidebarWidth,
  },
}));

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const {
    children,
    headerTitle,
    hideBorder,
    useClientHeader,
    headerBreadcrumbs,
    headerRightContent,
  } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const router = useRouter();
  const { hideNav } = router.query;

  if (hideNav) {
    return <>{children} </>;
  }

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      {isAdvisorLoggedInAsClient && <AdvisorImpersonationBar />}
      <DashboardNavbar
        onOpenSidebar={(): void => {
          setIsSidebarOpen(true);
        }}
        title={headerTitle}
        hideBorder={hideBorder}
        useClientHeader={useClientHeader}
        breadcrumbItems={headerBreadcrumbs}
        rightContent={headerRightContent}
      />
      <DashboardSidebar
        onClose={(): void => {
          setIsSidebarOpen(false);
        }}
        open={isSidebarOpen}
      />
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
