import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import { XNavLink } from "shared/x-components/x-nav-link";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Collapse,
  Divider,
  ListItem,
  Tooltip,
} from "@mui/material";
import type { ListItemProps } from "@mui/material";
import { useTheme } from "shared/hooks/use-theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
type DashboardSidebarItemProps = {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  disabled?: boolean;
  open?: boolean;
  path?: string;
  title: string;
} & ListItemProps;

function dislabedItemTooltipTitle(path?: string) {
  if (path === "/portfolio" || path?.startsWith("/savvy-portfolio")) {
    return "Your portfolio will be available for you to view after your accounts are funded.";
  }
  if (path === "/goals") {
    return "You can track your financial goals in Savvy. Reach out to your advisor to begin.";
  }
  if (path === "/tasks") {
    return "Tasks created by your advisor will be available for you to view after your accounts are funded.";
  }
  return "This will be available once you are fully onboarded.";
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    disabled,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp || false);
  const theme = useTheme();

  useEffect(() => {
    setOpen(!!openProp);
  }, [openProp]);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  const sharedListItemSpacingSX = {
    py: 0,
  };
  const sharedButtonSpacingSX = {
    pl: `12px`,
    ml: 2 + 3 * depth,
    pr: 3,
  };

  const sharedButtonProps = {
    disabled,
    disableRipple: true,
    startIcon: icon,
    width: "100%",
  };

  // Branch
  if (children && !disabled) {
    return (
      <>
        <ListItem
          disableGutters
          sx={{
            ...sharedListItemSpacingSX,
            display: "flex",
          }}
          {...other}
        >
          <Button
            onClick={handleToggle}
            {...sharedButtonProps}
            endIcon={
              open ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )
            }
            size="small"
            sx={{
              ...sharedButtonSpacingSX,
              color: "text.charcoal",
              justifyContent: "flex-start",
              textAlign: "left",
              textTransform: "none",
              height: "34px",
              width: "100%",
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              "& .MuiButton-startIcon": {
                color: "neutral.900",
              },
              "& .MuiButton-endIcon": {
                color: "neutral.900",
                ml: 13.5,
              },
            }}
          >
            <Box sx={{ flexGrow: 0 }}>{title}</Box>
            {info}
          </Button>
        </ListItem>
        <Collapse in={open} sx={{ mt: 0.5, pr: 3 }}>
          {children}
        </Collapse>
      </>
    );
  }

  const leafButton = (
    <Button
      onClick={
        title === "Files" && active
          ? () => {
              window.location.reload();
            }
          : () => {
              return;
            }
      }
      size="small"
      endIcon={chip}
      sx={{
        ...sharedButtonSpacingSX,
        backgroundColor: active ? theme.palette.action.selected : undefined,
        borderRadius: 1,
        color: "neutral.900",
        fontWeight: active ? "fontWeightBold" : undefined,
        justifyContent: "flex-start",
        textAlign: "left",
        textTransform: "none",
        height: "34px",
        width: "100%",
        "& .MuiButton-startIcon": {
          color: "neutral.900",
        },
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
        pointerEvents: path === "divider" ? "none" : "auto",
      }}
      {...sharedButtonProps}
    >
      <Box sx={{ flexGrow: 1 }}>{path === "divider" ? <Divider /> : title}</Box>
      {info}
    </Button>
  );

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        ...sharedListItemSpacingSX,
        display: "flex",
        mb: "2px",
        pr: 2,
      }}
    >
      {!disabled &&
        (title !== "Files" || !active ? (
          <XNavLink
            // eslint-disable-next-line no-script-url -- FIXME: Shouldn't be using eval methods
            href={path === "divider" ? "javascript:void(0)" : path!}
            webOnlyPassHref
            // @ts-expect-error This can be fixed when we feel like supporting this feature on mobile
            style={{
              width: "inherit",
              textDecoration: "none",
            }}
          >
            {leafButton}
          </XNavLink>
        ) : (
          leafButton
        ))}
      {disabled && (
        <Tooltip title={dislabedItemTooltipTitle(path)}>
          <span>{leafButton}</span>
        </Tooltip>
      )}
    </ListItem>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
};
