import { AppBar, Stack, Typography } from "@mui/material";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import { useAuth } from "shared/hooks/use-auth";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { LogoutAsButton } from "../logout-as-button";
import { advisorLoginAsBannerColor } from "shared/theme";
import { graphql } from "shared/gql";
import { useTheme } from "shared/hooks/use-theme";

const CLIENT_QUERY = graphql(/* GraphQL */ `
  query ClientFromDashboardAdvisorImpersonationBar($id: ID!) {
    client(id: $id) {
      user {
        id
        firstName
        lastName
        email
        profilePicUrl
      }
      household {
        name
        salesforceAccountId
      }
    }
  }
`);

export const AdvisorImpersonationBar = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { data } = useQueryWithErrorLogging(CLIENT_QUERY, {
    variables: {
      id: user?.id!,
    },
  });
  const client = data?.client;
  const clientName = `${client?.user.firstName} ${client?.user.lastName}`;
  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        zIndex: 999999,
        backgroundColor: advisorLoginAsBannerColor,
      }}
      color="primary"
    >
      {client && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height={64}
        >
          <Stack direction="row" alignItems="center" ml={3}>
            <SupervisorAccountRoundedIcon
              sx={{ color: theme.palette.neutral[500] }}
            />
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ ml: 1 }}
            >
              {`Logged in as ${clientName}`}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ ml: 1 }}>
              {`Changes will be visible to
            ${client.household.name.replace("Household", "")}`}
            </Typography>
          </Stack>
          <LogoutAsButton
            destinationUrl={`/advisor/dashboard/client/${client.household.salesforceAccountId}`}
          />
        </Stack>
      )}
    </AppBar>
  );
};
