export const SleeveTagIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.49967 16.6673C11.1816 16.6673 14.1663 13.6825 14.1663 10.0007C14.1663 6.31875 11.1816 3.33398 7.49967 3.33398C3.81778 3.33398 0.833008 6.31875 0.833008 10.0007C0.833008 13.6825 3.81778 16.6673 7.49967 16.6673Z"
      fill="url(#paint0_radial_960_18109)"
    />
    <path
      d="M14.166 3.55078V5.29245C16.1077 5.97578 17.4993 7.82578 17.4993 10.0008C17.4993 12.1758 16.1077 14.0258 14.166 14.7091V16.4508C17.041 15.7091 19.166 13.1091 19.166 10.0008C19.166 6.89245 17.041 4.29245 14.166 3.55078Z"
      fill="url(#paint1_radial_960_18109)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_960_18109"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.833 7.30224) rotate(129.289) scale(15.7919)"
      >
        <stop stopColor="#8D34FE" />
        <stop offset="1" stopColor="#CAD7FA" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_960_18109"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.916 7.39007) rotate(107.595) scale(12.4054 7.29355)"
      >
        <stop stopColor="#6C1BD3" />
        <stop offset="1" stopColor="#D3F6F4" />
      </radialGradient>
    </defs>
  </svg>
);
