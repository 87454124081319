import React, { ReactNode } from "react";
import { Avatar } from "@mui/material";

export function renderItemIcon(background: string, component: ReactNode) {
  return (
    <Avatar variant="circular" sx={{ background }}>
      {component}
    </Avatar>
  );
}
