import { Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { XNavLink } from "shared/x-components/x-nav-link";

export type BreadcrumbData = {
  href?: string;
  title: string | undefined;
  detail?: ReactElement;
  icon?: ReactElement;
};

export const formatBreadcrumbList = (crumbs: BreadcrumbData[]) => {
  const breadcrumbs = crumbs.slice(0, -1).map(formatInactiveBreadcrumb);
  breadcrumbs.push(formatActiveBreadcrumb(crumbs[crumbs.length - 1]));
  return breadcrumbs;
};

export const formatInactiveBreadcrumb = (crumb: BreadcrumbData) => {
  const { href, title } = crumb;
  const titleText = (
    <Typography
      key={title}
      variant="subtitle1"
      color="text.secondary"
      sx={{
        "&:hover": {
          textDecoration: "underline",
        },
      }}
    >
      {title}
    </Typography>
  );
  if (!href) {
    return titleText;
  }
  return (
    <XNavLink key={title} href={href} webOnlyPassHref>
      {titleText}
    </XNavLink>
  );
};

export const formatActiveBreadcrumb = (crumb: BreadcrumbData) => {
  const { href, title, detail, icon } = crumb;
  const titleText = (
    <Stack key={title} direction="row" alignItems="center">
      {icon}
      <Typography
        variant="subtitle1"
        color="text.primary"
        mr={1}
        ml={icon ? 1 : 0}
      >
        {title}
      </Typography>
      {detail}
    </Stack>
  );
  if (!href) {
    return titleText;
  }
  return (
    <XNavLink key={title} href={href} webOnlyPassHref>
      {titleText}
    </XNavLink>
  );
};
