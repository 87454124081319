import { ReactNode, useEffect, useMemo } from "react";
import { XNavLink } from "shared/x-components/x-nav-link";
import NextLink from "next/link";
import { useRouter } from "next/router";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { Home as HomeIcon } from "../../icons/home";
import { UserCircle as UserCircleIcon } from "../../icons/user-circle";
import CheckCircleIcon from "../../icons/custom/check-circle";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocalFloristRoundedIcon from "@mui/icons-material/LocalFloristRounded";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import FolderIcon from "@mui/icons-material/Folder";
import { Scrollbar } from "../scrollbar";
import { DashboardSidebarSection } from "./dashboard-sidebar-section";
import { isAdvisor } from "src/utils/account";
import { Theme } from "@mui/system";
import { useTheme } from "shared/hooks/use-theme";
import { useAuth } from "shared/hooks/use-auth";
import { Cog as CogIcon } from "../../icons/cog";
import { ClientStage, Portfolio } from "shared/generated/graphql";
import { sidebarWidth } from "shared/theme";
import { Map } from "@mui/icons-material";
import { isSavvyTestUserEmail } from "shared/utils/internal-savvy";
import { formatHouseholdName } from "src/utils/formatting";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { loggedInAsAdvisorID } from "shared/utils/savvy-auth";
import { SavvyCircleIcon } from "../savvy-circle-icon";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "launchdarkly-react-client-sdk/src/withLDConsumer";
import { graphql } from "shared/gql";
import { JWTContextUser } from "shared/api/user-api";
import DashboardSidebarLogOutButton from "./dashboard-sidebar-log-out-button";
import { XSavvyLogo } from "shared/x-components/x-savvy-logo";

type DashboardSidebarProps = LDProps & {
  onClose: () => void;
  open: boolean;
};

type Item = {
  title: string;
  disabled?: boolean;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
};

type Section = {
  title: string;
  items: Item[];
};
const sortedActiveItems = (items: Item[]) => {
  return items.sort(
    (a, b) =>
      // Excluding portfolio menu items from sorting even if disabled
      (Number(a.disabled && !a.path?.includes("portfolio")) || -1) -
      (Number(b.disabled && !a.path?.includes("portfolio")) || -1)
  );
};

const getAdvisorSections = (): Section[] => [
  {
    title: "General",
    items: [
      {
        title: "Clients",
        path: "/advisor",
        icon: <HomeIcon fontSize="small" />,
      },
      {
        title: "Account",
        path: "/dashboard/account",
        icon: <CogIcon fontSize="small" />,
      },
    ],
  },
];

const getClientSections = ({
  numberOfUndoneTasks,
  userHasGoals,
  disableSavvyInvestmentsTab,
  secondaryPortfolios,
  personalPortfolio,
  user,
}: {
  numberOfUndoneTasks: number;
  userHasGoals: boolean;
  disableSavvyInvestmentsTab: boolean;
  secondaryPortfolios?: Portfolio[] | null;
  personalPortfolio?: Portfolio | null;
  user: JWTContextUser;
}): Section[] => {
  const isUserFullyOnboarded =
    user != null &&
    (!user.clientStage ||
      user.clientStage === ClientStage.FullyOnboarded ||
      isSavvyTestUserEmail(user.email));

  const netWorthItem = {
    title: "Net worth",
    path: "/portfolio",
    icon: <SignalCellularAltRoundedIcon fontSize="small" />,
  };
  const savvyManagedItem = {
    title:
      secondaryPortfolios && secondaryPortfolios.length > 0
        ? "Personal investments"
        : "Savvy investments",
    disabled: !isUserFullyOnboarded || disableSavvyInvestmentsTab,
    path: `/savvy-portfolio/${personalPortfolio?.id}`,
    icon:
      isUserFullyOnboarded && !disableSavvyInvestmentsTab ? (
        <SavvyCircleIcon />
      ) : (
        <SavvyCircleIcon disabled />
      ),
  };

  let savvySecondaryPortfolioItems: {
    title: string;
    path: string;
    icon: JSX.Element;
  }[] = [];

  if (secondaryPortfolios) {
    savvySecondaryPortfolioItems = secondaryPortfolios.map((portfolio) => ({
      title: portfolio.displayName || portfolio.name,
      path: `/savvy-portfolio/${portfolio.id}`,
      icon: <SavvyCircleIcon />,
    }));
  }

  const dividerItem = {
    title: "divider",
    path: "divider",
  };

  const goalsItem = {
    title: "Goals",
    disabled: !userHasGoals,
    path: "/goals",
    icon: (
      <LocalFloristRoundedIcon
        color={!userHasGoals ? "disabled" : undefined}
        fontSize="small"
      />
    ),
  };
  const tasksItem = {
    title: "Tasks",
    path: "/tasks",
    icon: <CheckCircleIcon />,
    chip:
      numberOfUndoneTasks > 0 ? (
        <Badge badgeContent={numberOfUndoneTasks} color="primary" />
      ) : null,
  };
  const advisorItem = {
    title: "Advisor",
    path: "/dashboard/advisor",
    icon: <PeopleRoundedIcon fontSize="small" />,
  };
  const filesItem = {
    title: "Files",
    path: "/files",
    icon: <FolderIcon fontSize="small" />,
  };
  const accountItem = {
    title: "Account",
    path: "/dashboard/account",
    icon: <CogIcon fontSize="small" />,
  };

  if (isUserFullyOnboarded) {
    return [
      {
        title: "",
        items: sortedActiveItems([
          netWorthItem,
          savvyManagedItem,
          ...savvySecondaryPortfolioItems,
          dividerItem,
          goalsItem,
          tasksItem,
          advisorItem,
          filesItem,
          accountItem,
        ]),
      },
    ];
  }

  const progressItem = {
    title: "Getting started",
    path: "/dashboard",
    icon: <Map />,
  };
  return [
    {
      title: "",
      items: sortedActiveItems([
        progressItem,
        netWorthItem,
        savvyManagedItem,
        goalsItem,
        tasksItem,
        advisorItem,
        filesItem,
        accountItem,
      ]),
    },
  ];
};

const getSections = (
  advisorView: boolean,
  numberOfUndoneTasks: number,
  userHasGoals: boolean,
  disableSavvyInvestmentsTab: boolean,
  secondaryPortfolios: [Portfolio],
  personalPortfolio: Portfolio,
  user: JWTContextUser
): Section[] => {
  if (advisorView) {
    return getAdvisorSections();
  }
  return getClientSections({
    numberOfUndoneTasks,
    userHasGoals,
    disableSavvyInvestmentsTab,
    secondaryPortfolios,
    personalPortfolio,
    user,
  });
};

export const GET_CLIENT_SIDEBAR_DATA = graphql(/* GraphQL */ `
  query ClientSidebarData($id: ID) {
    client(id: $id) {
      id
      numberOfTodoTasks
      household {
        orionHouseholdId
        adviceOnly
        name
        users {
          lastName
          firstName
        }
        portfolios {
          id
          householdId
          name
          displayName
          isPersonal
          orionHouseholdId
        }
        clientPortalMessage {
          message
          createdAt
          primaryClientHasRead
          coClientHasRead
        }
      }
      user {
        isPrimaryClient
      }
    }
    goals(userId: $id) {
      id
    }
  }
`);

export const DashboardSidebar = (props: DashboardSidebarProps) => {
  const { onClose, open, flags } = props;
  const router = useRouter();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const auth = useAuth();
  const { user } = auth;
  const advisorView = isAdvisor(auth);
  const theme = useTheme();

  const isAdvisorLoggedInAsClient = !!loggedInAsAdvisorID();

  const { data } = useQueryWithErrorLogging(GET_CLIENT_SIDEBAR_DATA, {
    skip: advisorView,
    variables: {
      id: user?.id,
    },
  });

  function shouldShowClientPortalMessageChip(): boolean {
    const clientPortalMessage = data?.client.household.clientPortalMessage;

    if (data?.client.user.isPrimaryClient) {
      return !!clientPortalMessage && !clientPortalMessage.primaryClientHasRead;
    }
    return !!clientPortalMessage && !clientPortalMessage.coClientHasRead;
  }

  const numberOfTodoTasks = data?.client.numberOfTodoTasks || 0;
  const goals = data?.goals || [];
  const userHasGoals = goals.length > 0;
  const disableSavvyInvestmentsTab =
    !!data?.client.household.adviceOnly ||
    !data?.client.household.orionHouseholdId;
  const secondaryPortfolios = data?.client.household.portfolios.filter(
    (portfolio: { isPersonal: boolean }) => !portfolio.isPersonal
  );

  const personalPortfolio = data?.client.household.portfolios.find(
    (portfolio: { isPersonal: boolean }) => portfolio.isPersonal
  );

  const sections = useMemo(() => {
    return getSections(
      advisorView,
      numberOfTodoTasks,
      userHasGoals,
      disableSavvyInvestmentsTab,
      // @ts-expect-error - Should fix itself as we increase typing
      secondaryPortfolios,
      personalPortfolio,
      user
    );
  }, [
    advisorView,
    numberOfTodoTasks,
    userHasGoals,
    disableSavvyInvestmentsTab,
    secondaryPortfolios,
    personalPortfolio,
    user,
    flags,
  ]);

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath]
  );

  if (!user) {
    return null;
  }
  const content = (
    <>
      {(data || advisorView) && (
        <Scrollbar
          sx={{
            height: "100%",
            "& .simplebar-content": {
              height: "100%",
            },
            mt: isAdvisorLoggedInAsClient ? 8 : "",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div>
              <Box
                sx={{
                  p: 3,
                  alignItems: "center",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                component={NextLink}
                href="/dashboard/account"
              >
                <Avatar
                  src={user.profilePicUrl || ""}
                  sx={{
                    height: 80,
                    width: 80,
                    mb: 1,
                  }}
                >
                  <UserCircleIcon fontSize="small" />
                </Avatar>

                <Typography variant="h6" color="text.charcoal">
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  textAlign="center"
                >
                  {formatHouseholdName(data?.client.household)}
                </Typography>
                <Collapse in={shouldShowClientPortalMessageChip()}>
                  <Chip
                    label="1 message"
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 1 }}
                    component={NextLink}
                    href="/dashboard/advisor"
                    clickable
                  />
                </Collapse>
              </Box>
            </div>

            <Box sx={{ flexGrow: 1, pr: 2 }}>
              {sections.map((section) => (
                <DashboardSidebarSection
                  key={section.title}
                  path={router.asPath}
                  sx={{
                    mt: 2,
                    "& + &": {
                      mt: 2,
                    },
                  }}
                  {...section}
                />
              ))}
              {!advisorView && (
                <Box
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    my: 2,
                  }}
                >
                  <XNavLink href="/dashboard/advisor">
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ width: "200px", border: "1px solid" }}
                    >
                      Contact your team
                    </Button>
                  </XNavLink>
                </Box>
              )}
            </Box>

            <Stack
              alignItems="flex-start"
              spacing={1.5}
              sx={{
                pl: 2,
                pr: 2,
                mb: 3,
              }}
            >
              <DashboardSidebarLogOutButton onLogOut={onClose} fullWidth />
              <Stack spacing={2.5} px={1.5} width="100%">
                <Divider flexItem />
                <Box width={66}>
                  <XSavvyLogo fill={theme.palette.neutral[500]} />
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Scrollbar>
      )}
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.contrast",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: 1,
            color: "neutral.50",
            width: sidebarWidth,
            boxShadow: "none",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          color: "neutral.0",
          width: sidebarWidth,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default withLDConsumer()(DashboardSidebar);
