import {
  AirportShuttle,
  BusinessCenter,
  Store,
  RequestQuote,
  School,
  CreditCard,
} from "@mui/icons-material";
import { renderItemIcon } from "src/components/portfolio/views/render-item-icon";
import {
  LIABILITY_TYPE_DISPLAY_NAME,
  isLiabilityTypeName,
} from "src/constants/account-link";
import { PortfolioItemTypeName } from "shared/generated/string_unions/graphql";
import { LiabilityTypeName } from "shared/generated/graphql";
import { financialAccountIconColors } from "shared/theme";
import defaultGuard from "shared/utils/default-guard";
import { formatItemValue } from "./portfolio-item";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";

export const RenderIconForLiabilityFragment = graphql(/* GraphQL */ `
  fragment RenderIconForLiability on Liability {
    id
    type
  }
`);

export function renderIconForLiability(
  liabilityFrag: FragmentOf<typeof RenderIconForLiabilityFragment>
) {
  const { type, id } = unmaskFragment(
    RenderIconForLiabilityFragment,
    liabilityFrag
  );
  if (!isLiabilityTypeName(type)) {
    return <img src={`https://avatar.tobi.sh/Liability${id}?size=30`} />;
  }
  switch (type) {
    case LiabilityTypeName.Auto:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <AirportShuttle fontSize="small" htmlColor="#fff" />
      );
    case LiabilityTypeName.Commercial:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <BusinessCenter fontSize="small" htmlColor="#fff" />
      );
    case LiabilityTypeName.Consumer:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <Store fontSize="small" htmlColor="#fff" />
      );
    case LiabilityTypeName.LineOfCredit:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <RequestQuote fontSize="small" htmlColor="#fff" />
      );
    case LiabilityTypeName.Student:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <School fontSize="small" htmlColor="#fff" />
      );
    case LiabilityTypeName.General:
    case LiabilityTypeName.HomeEquity:
    case LiabilityTypeName.Mortgage:
    case LiabilityTypeName.Other:
      return renderItemIcon(
        financialAccountIconColors.liability,
        <CreditCard fontSize="small" htmlColor="#fff" />
      );
    default:
      defaultGuard(type);
      return renderItemIcon(
        financialAccountIconColors.liability,
        <CreditCard fontSize="small" htmlColor="#fff" />
      );
  }
}

export const LIABILITY_DELETION_TEXT =
  "When you delete a liability it will no longer appear in your list of accounts, assets, and liabilities. Historical values of this liability will be removed from your net worth graph.";

export const LIABILITY_DELETE_BUTTON_TEXT = "Delete liability";

export function liabilityTitle({ liabilityName }: { liabilityName: string }) {
  return liabilityName;
}

export function liabilitySubtitle(liabilityType: PortfolioItemTypeName) {
  if (!isLiabilityTypeName(liabilityType)) {
    return liabilityType;
  }
  return LIABILITY_TYPE_DISPLAY_NAME[
    isLiabilityTypeName(liabilityType) ? liabilityType : LiabilityTypeName.Other
  ];
}

export function liabilityValueTitle(liability: {
  currentBalance: { amount: string };
}) {
  return formatItemValue(-liability.currentBalance.amount);
}
