export const SavvyCircleIcon = ({
  disabled = false,
  height = 16,
  width = 16,
}: {
  disabled?: boolean;
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 326 326"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="163"
        cy="163"
        r="163"
        fill={disabled ? "#c7cbcf" : "#1D2334"}
      />
      <path
        d="M162.991 131.524C135.823 131.524 110.518 140.856 95.3904 156.121C91.7508 150.429 89.7326 144.185 89.7326 137.63C89.7326 111.169 122.593 89.6247 162.991 89.6247C203.389 89.6247 236.249 111.152 236.249 137.63H244.874C244.891 106.408 208.15 81 162.991 81C117.832 81 81.1079 106.408 81.1079 137.63C81.1079 168.851 117.849 194.26 162.991 194.26C190.159 194.26 215.464 184.928 230.592 169.662C234.231 175.354 236.249 181.599 236.249 188.154C236.249 214.614 203.389 236.159 162.991 236.159C122.593 236.159 89.7326 214.614 89.7326 188.154H81.1079C81.1079 219.375 117.849 244.783 162.991 244.783C208.133 244.783 244.874 219.375 244.874 188.154C244.874 156.932 208.133 131.524 162.991 131.524ZM162.991 185.635C136.755 185.635 113.709 176.545 100.772 162.935C114.175 148.808 137.6 140.148 162.991 140.148C188.382 140.148 212.273 149.239 225.21 162.849C211.807 176.976 188.382 185.635 162.991 185.635Z"
        fill="white"
      />
    </svg>
  );
};
